import React from 'react';
import GlobalLayout from "@myaccount/GlobalLayout";
import MyAccountNewsOverview from '@myaccount/containers/news';
import { MyaccountStoreProvider, useAuthState } from "@myaccountServices";
import { withSubtheme } from '@starberryComponentsMui';
import defaults from '@myaccount/blog/defaults';

const MyAccountNewsOverviewPageWrap = (props) => {
    const { services: authServices } = useAuthState()

    React.useEffect(() => {
        authServices.updateState({pageTitle:`News`, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: false});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <MyAccountNewsOverview {...props} />;
}

const MyAccountNewsOverviewPage = withSubtheme((props) => {
    const {
        api,
        className,
        pageTitle,
        _data,
        loadMoreBtnProp,
        loadMoreBtnLabel
    } = props;

    return (
        <MyaccountStoreProvider>
            <GlobalLayout>
                <MyAccountNewsOverviewPageWrap
                    api={api}
                    className={className}
                    pageTitle={pageTitle}
                    _data={_data}
                    loadMoreBtnProp={loadMoreBtnProp}
                    loadMoreBtnLabel={loadMoreBtnLabel}
                />
            </GlobalLayout>
        </MyaccountStoreProvider>
    )
}, 'newsOverviewPage', defaults)

export default React.memo(MyAccountNewsOverviewPage);

/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/
//import Typography from "@mui/material/Typography";
//import Grid from "@material-ui/core/Grid";

import { useTheme } from "@starberryComponentsMui"
import {
    MYACCOUNT_NEWS_OVERVIEW,
    MYACCOUNT_NEWS_DETAILS
} from "@myaccountUrls";

import PrivateRoute from "@myaccount/hoc/PrivateRoute"
import NewsOverviewPage from "@myaccount/pages/news"
import NewsDetailsPage from "@myaccount/pages/news/details"
import PageHeaderHelmet from "@components/pageHeader";

const MyAccountPages = () => {

    const theme = useTheme()

    return (
        <>
            <PageHeaderHelmet theme={theme} />
            <Router>
                <PrivateRoute
                    path={MYACCOUNT_NEWS_OVERVIEW}
                    component={NewsOverviewPage}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_NEWS_DETAILS}
                    component={NewsDetailsPage}
                    theme={theme}
                />
            </Router>
        </>
    )
}

export default MyAccountPages

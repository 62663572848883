/* eslint-disable import/first */
import React from "react";

import {
    withSubtheme
} from '@starberryComponentsMui';
import { useAuthState } from "@myaccountServices";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import PageTitle from '@myaccount/pageTitle';
import BannerImage from '../components/bannerImage';
import { displayData } from '@myaccount/helper/dateformat';
import BackButton from '@myaccount/backButton';

import defaults from './defaults';

// const NewlineToPara = (props) => {
//     const text = props.text;
//     const newText = text.split('\n').map(str => <Typography>{str}</Typography>);
//     return <div className="propertyDetailsDescription">{newText}</div>
// }



const NewsDetailWrapper = withSubtheme(props => {
    const {
        className,
        data,
        newsItemDateProps
    } = props

    const { services: authServices } = useAuthState()

    React.useEffect(() => {
        authServices.updateState({pageTitle:data?.title, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: true});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const imgData = [
        {
            "url": "https://assets.reapit.net/aac/live/pictures/PRP/21/PRP210070_45.jpg",
            "alt": `${data?.title}`,
        }
    ]

    const createFullPostMarkup = () => {
        return { __html: data?.description }
    }

    return (
        <Container maxWidth="xl" classes={{root: className}}>
            <Grid container>
                <Grid item xs={12}>
                    <BannerImage data={imgData} />
                </Grid>
                <Grid item xs={12}>
                    <BackButton />
                    <PageTitle>{data?.title}</PageTitle>
                    {data?.publish_start_date && <Typography {...newsItemDateProps}>{data.publish_start_date ? displayData(data.publish_start_date) : null}</Typography>}
                </Grid>
                <Grid container item spacing={1}>
                    <Grid item xs={12} lg={8}>
                        {data?.description &&
                            <article className="newsDetails-content" dangerouslySetInnerHTML={createFullPostMarkup()} />
                        }
                    </Grid>
                    {/*<Grid item xs={12} md={4}>
                        <FloatingContainer>
                            <ContactBtn />
                        </FloatingContainer>
                    </Grid>*/}
                </Grid>
            </Grid>
        </Container>
    )
}, 'newsDetailsPage', defaults)

export default React.memo(NewsDetailWrapper);

import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import defaults from './defaults';

const BannerImage = withSubtheme((props) => {
    const {
        className,
        data,
    } = props
    return(
        <>
            {data.map((img, i) => {
                return(
                    <div
                        key={i}
                        className={className}
                        style={{
                            "backgroundImage": `url("${img.url}")`
                        }}
                        alt={img.alt}
                    />
                )
            })}
        </>
    )
}, "newsBannerImage", defaults)

export default BannerImage

import React from 'react';
import NewsOverview from '@myaccount/blog';
import { useMyaccountState } from "@myaccountServices";

const MyAccountNewsOverview = ({api, ...props}) => {

    const [page, setPage] = React.useState(1);

    const { state, services } = useMyaccountState();
    let newsItems =  state?.newsItems || [];
    const newsItemsTotal = state?.newsItemsTotal || 0;
    const loading = state?.loading || false;

    const {
        limit,
        query,
        endpoint,
        fieldMapping,
        linkToWebsite
    } = api;

    React.useEffect(() => {
        services.getNewsItems({
            _sort:'publish_start_date:DESC',
            _start: (page - 1) * limit,
            _limit: limit,
            endpoint: endpoint,
            ...query
        });
        services.countNewsItems({endpoint: endpoint});
    },[page]);

    return (
        <NewsOverview
            {...props}
            fieldMapping={fieldMapping}
            linkToWebsite={linkToWebsite}
            data={newsItems}
            loading={loading && page === 1 && true}
            nextPageLoading={loading}
            onNext={() => setPage(page + 1)}
            hasNext={((page - 1) * limit) + limit < newsItemsTotal && newsItems.length > 0}
        />
    );
}

export default MyAccountNewsOverview;

import React from 'react';
import { get } from 'lodash';
import { withSubtheme } from '@starberryComponentsMui';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { newsDate } from '@myaccount/helper/dateformat';
import { WEBSITE_URL, GGFX_ENV } from "@myaccountUrls";

import ImageTransform from "@components/Common/ggfx-client/module/components/image-transform";
import { ShowProcessedImage } from '@components/Common/ggfx-client/module/components/show-image';
import { GetUrlSet } from '@components/Common/ggfx-client/client';
import imageConfig from "../../../../../static/images/config.json"

import {
    MYACCOUNT_NEWS_OVERVIEW,
} from "@myaccountUrls";

import defaults from './defaults';

const STAGE_ENV = GGFX_ENV;

const MyAccountNewsItem = withSubtheme((props) => {
    const {
        className,
        news,
        newsItemProps,
        newsItemCardProps,
        newsItemTitleProps,
        newsItemImageProps,
        newsItemDateShow,
        newsItemDateProps,
        newsItemIntroProps,
        newsItemIntroLimit,
        newsItemActionsBtnProps,
        newsItemActionsBtnIconProps,
        fieldMapping,
    } = props;

    const { id, intro_image_alt } = news

    const title                 = get(news, `${fieldMapping.title}`, 'no title');
    const publish_start_date    = get(news, `${fieldMapping.publish_start_date}`, 'no date');
    const intro_image           = get(news, `${fieldMapping.intro_image}`, `${process.env.GATSBY_NO_IMAGE}`);
    const introtext             = get(news, `${fieldMapping.introtext}`, ``);
    const tag                   = get(news, `${fieldMapping.tag}`, ``);
    const category              = get(news, `${fieldMapping.category}`, ``);
    const urlLevel              = get(fieldMapping, `urlLevel`, ``);
    const baseUrl               = get(fieldMapping, `baseUrl`, ``);
    const imagename             = get(fieldMapping, `imagename`, ``);
    const transforms            = get(fieldMapping, `transforms`, ``);

    let link = `${MYACCOUNT_NEWS_OVERVIEW}/${id}`;
    let cardAreaParams = {
        href: link
    }
    if ( get(props, 'linkToWebsite', false) === true)  {
        let urlParams = [];
        if (baseUrl) {
            urlParams.push(baseUrl)
        }
        for (var i = 1; i <= urlLevel; i++) {
            let field = `urlLevel_${i}`
            let level = get(news, `${fieldMapping[field]}`, ``);
            if (level)
                urlParams.push(level)
        }

        link = `${WEBSITE_URL}/${urlParams.join('/')}`;
        cardAreaParams = {
            href: link,
            target: '_blank'
        }
    }

    let image_processed = false;
    let processedImages = JSON.stringify({});
    if (news?.imagetransforms?.Tile_Image_Transforms) {
        processedImages = news?.imagetransforms?.Tile_Image_Transforms;
        // console.log("processedImages => ", GetUrlSet({
        //     srcCftle: intro_image.substring(intro_image.indexOf(STAGE_ENV)),
        //     format: 'jpg',
        //     transforms: JSON.stringify(['330x260']),
        //     //Need to confirm because of i.dev/i.prod env so id could be change
        //     id: news.id,
        //     //Flag to update processed data into strapi collection
        //     updateFlag: true,
        //     fieldName: 'Tile_Image',
        //     modelName: 'property-news'
        // }));

        // (async function (parameters) {
        //     try {
        //         const data = await GetUrlSet({
        //             srcCftle: intro_image.substring(intro_image.indexOf(STAGE_ENV)),
        //             format: 'jpg',
        //             transforms: JSON.stringify(['330x260']),
        //             //Need to confirm because of i.dev/i.prod env so id could be change
        //             id: news.id,
        //             //Flag to update processed data into strapi collection
        //             updateFlag: true,
        //             fieldName: 'Tile_Image',
        //             modelName: 'property-news'
        //         })
        //
        //         console.log();
        //     } catch (e) {
        //
        //     }
        // })(arguments);

        const searchStr = GGFX_ENV;

        // check if it is already processed image or not
        if (intro_image.indexOf(searchStr) > -1)
            image_processed = true
    }

    let shortIntro = introtext;

    if (shortIntro) {
        shortIntro = shortIntro.replace(/<[^>]+>/g, ''); // Remove all html elements
        shortIntro = `${shortIntro.substring(0, newsItemIntroLimit)}...`;
    }
        const date = publish_start_date ? newsDate(publish_start_date) : null;

        return (
            <Grid {...newsItemProps} classes={{root: className}}>
                <Card {...newsItemCardProps} classes={{root: "newsItemCard"}}>
                    <CardActionArea {...cardAreaParams}>
                        {intro_image &&
                        <div className="newsItemImageContainer">
                            {!image_processed && (
                                <CardMedia {...newsItemImageProps}
                                           alt={intro_image_alt}
                                           image={intro_image}
                                           title={title}
                                           classes={{root: "newsItemImage"}}
                                />
                            )}
                            {image_processed && (
                                <ImageTransform imagesources={intro_image} renderer="srcSet" imagename={imagename} transforms={transforms}
                                                attr={{ alt: 'Property image', className:`newsItemImage MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img`}}
                                                imagetransformresult={processedImages} id={news.id} />
                            )}
                        </div>
                        }
                        <CardContent classes={{root: "newsItemContent"}}>
                            {title && <Typography className={`newsItemTitle`} {...newsItemTitleProps}>{title}</Typography>}
                            {publish_start_date && newsItemDateShow && <Typography {...newsItemDateProps}>{date}</Typography>}
                            {shortIntro && <Typography {...newsItemIntroProps}>{shortIntro}</Typography>}
                        </CardContent>
                    </CardActionArea>
                    {(category || tag ) && (
                        <CardActions classes={{root: "newsItemActions"}}>
                            {category && (
                                <Button {...newsItemActionsBtnProps}><LocalOfferIcon classes={{root: "newsItemActionsBtnIcon" }} {...newsItemActionsBtnIconProps} /> {category}</Button>
                            )}
                            {tag && (
                                <Button {...newsItemActionsBtnProps}><LocalOfferIcon classes={{root: "newsItemActionsBtnIcon" }} {...newsItemActionsBtnIconProps} /> {tag}</Button>
                            )}
                        </CardActions>
                    )}
                </Card>
            </Grid>
        );
    //}


    //return null;
}, 'newsOverviewItem', defaults)

export default MyAccountNewsItem;

import React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import NewsItem from '@myaccount/blog/item';
import PageTitle from '@myaccount/pageTitle';

import defaults from './defaults';
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box"
import range from "lodash/range"
import ItemSkeleton from "./itemSkeleton"

const MyAccountNewsOverviewComp = (props) => {

    const {
        className,
        pageTitle,
        _data,
        data,
        onNext,
        loading,
        nextPageLoading,
        hasNext,
        loadMoreBtnProp,
        loadMoreBtnLabel,
        fieldMapping,
        linkToWebsite,
    } = props;

    const newsArr = data ? data : _data;

    return (
        <Container maxWidth="xl" classes={{root: className}}>
            <PageTitle>{pageTitle}</PageTitle>
            <Stack my={1} direction="column" spacing={2} alignItems="center">
                {
                    loading ? (
                        <Box mb={8} width="100%">
                            <Grid container spacing={1}>
                                {range(0, 6).map(i => (
                                    <Grid key={i} item xs={12} sm={6} md={4}>
                                        <ItemSkeleton />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    ) : (
                        <React.Fragment>
                            <Box>
                                <Grid container spacing={1}>
                                    {newsArr
                                        // .filter(news => news.published)
                                        .map((news, i) => (
                                            <NewsItem news={news} key={news.id} fieldMapping={fieldMapping} linkToWebsite={linkToWebsite} />
                                        ))
                                    }
                                </Grid>
                            </Box>
                            {hasNext && (
                                <Button {...loadMoreBtnProp} disabled={!hasNext} onClick={onNext}>
                                    {nextPageLoading ? 'Loading...' : loadMoreBtnLabel}
                                </Button>
                            )}
                        </React.Fragment>
                    )
                }

            </Stack>
        </Container>
    );
}

export default MyAccountNewsOverviewComp;
